import { CheckIcon, ClockIcon, EyeIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { Loading } from 'components/Loading'
import { AccountType, TrustDetails } from 'config'
import {
  CertFormat,
  CertFormatStatus,
  CertFormatStatusStyles,
  CertFormatStatusText,
} from 'pages/Profile/CertFormatType'
import { Entities } from 'pages/Profile/Entities'
import { useEffect, useMemo, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { getUserCertFormation, updateUserCertFormation, updateUserData } from 'services/apis'
import { confirm, formatTime } from 'utils'

import { CertFormatHistoryModal } from './modal/CertFormatHistory'
import { CertFormatReasonModal } from './modal/CertFormatReason'
import type { IEntityData, User } from './type'

interface IProps {
  user: User
  onClose: Function
  onUpdateEntities: (data: IEntityData[]) => void
}

export const CertFormationDetails = (props: IProps) => {
  const { user, onClose, onUpdateEntities } = props

  const [searchParams] = useSearchParams()
  const defaultCertFormationId = Number(searchParams.get('certFormation') || 0)
  const [data, setData] = useState<CertFormat[]>()
  const [loading, setLoading] = useState(false)
  const [historyId, setHistoryId] = useState(0)
  const [rejectId, setRejectId] = useState(0)

  const navigate = useNavigate()

  const isEntity = useMemo(() => {
    return [AccountType.Company, AccountType.Individual].includes(user.accountType)
  }, [user])

  const isIRA = useMemo(() => {
    return [AccountType.Ira].includes(user.accountType)
  }, [user])

  useEffect(() => {
    if (defaultCertFormationId) navigate('/manageUsers')
  }, [])

  useEffect(() => {
    refresh()
  }, [user])

  const refresh = () => {
    setLoading(true)
    getUserCertFormation(user.id)
      .then(({ data }) => setData(data))
      .finally(() => setLoading(false))
  }

  const onView = (fileURL: string) => {
    window.open(fileURL, '_blank')
  }

  const onRejectClose = async (reason: string | null) => {
    const id = rejectId
    setRejectId(0)

    if (!reason) return

    setLoading(true)
    updateUserCertFormation(user.id, {
      id,
      status: CertFormatStatus.Rejected,
      reason,
    })
      .then(() => refresh())
      .finally(() => setLoading(false))
  }

  const onApprove = async (id: number) => {
    const result = await confirm('Are you sure to approve this document?')
    if (!result) return

    setLoading(true)

    updateUserCertFormation(user.id, {
      id,
      status: CertFormatStatus.Approved,
      reason: 'Approved',
    })
      .then(() => refresh())
      .finally(() => setLoading(false))
  }

  const onSaveEntity = async (data: IEntityData[]) => {
    setLoading(true)
    await updateUserData(user.id, { entities: data })
    await onUpdateEntities(data)
    setLoading(false)
  }

  const renderList = () => {
    if (!data || !data.length) return <></>
    const tableData = data.map((item, index) => {
      const targetEntity = user.entities.find((et) => et.certIDs.includes(item.id))
      const trustName = (user.accountDetails as TrustDetails).legalName

      const targetName = isEntity ? targetEntity?.entityName : trustName
      const rlt = [
        index + 1,
        targetName || '',
        item.fileName.length > 30 ? item.fileName.substring(0, 30) + '...' : item.fileName,
        <span className={`${CertFormatStatusStyles[item.status]} px-2 py-1 rounded`}>
          {CertFormatStatusText[item.status]}
        </span>,
        formatTime(item.createdAt),
        <div className="flex items-center gap-2 w-32">
          <div className="flex items-center gap-1 hover:underline cursor-pointer" onClick={() => setHistoryId(item.id)}>
            <ClockIcon className="w-4 h-4 text-indigo-600" />
            <p className="text-sm text-indigo-600">History</p>
          </div>
          <div className="flex items-center gap-1 hover:underline cursor-pointer" onClick={() => onView(item.fileURL)}>
            <EyeIcon className="w-4 h-4 text-indigo-600" />
            <p className="text-sm text-indigo-600">View</p>
          </div>
        </div>,
        item.status == CertFormatStatus.Pending && (
          <div className="flex items-center gap-2 w-32">
            <div className="flex items-center gap-1 hover:underline cursor-pointer" onClick={() => onApprove(item.id)}>
              <CheckIcon className="w-4 h-4 text-green-400" />
              <p className="text-sm text-green-600">Approve</p>
            </div>
            <div
              className="flex items-center gap-1 hover:underline cursor-pointer"
              onClick={() => setRejectId(item.id)}
            >
              <XMarkIcon className="w-4 h-4 text-red-600" />
              <p className="text-sm text-red-600">Reject</p>
            </div>
          </div>
        ),
      ]
      if (isIRA) rlt.splice(1, 1)
      return rlt
    })

    const header = ['No', isEntity ? 'Entity Name' : 'Trust Name', 'File Name', 'Status', 'Created At', 'Action']
    if (isIRA) header.splice(1, 1)
    return (
      <table className="w-full text-sm text-left text-gray-900 dark:text-gray-400 pl-6">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            {header.map((th, index) => (
              <th className={`px-3 py-3 ${index == 0 && 'w-12'}`} key={index}>
                {th}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((_, index) => (
            <tr key={index} className={`border-b ${index % 2 && 'bg-slate-50'} text-gray-900`}>
              {tableData[index].map((value, index) => (
                <td className="pl-3 py-2" key={index}>
                  {value}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    )
  }

  const renderHistoryModal = () => {
    if (!historyId || !data) return null

    const item = data.find((item) => item.id == historyId)
    if (!item) return null

    return <CertFormatHistoryModal createdBy history={item.history} onClose={() => setHistoryId(0)} />
  }

  return (
    <div className="relative my-2 border py-2 px-3 rounded bg-white">
      <div className="flex justify-between items-center">
        <h2 className="text-2xl font-bold mb-2 flex items-center gap-2">
          <span className="text-base">{isEntity ? 'Entity' : isIRA ? 'LLC' : 'Trust'} Documents</span>
          {loading && <Loading />}
        </h2>
        <span
          className="text-sm flex items-center ml-2 gap-1 text-red-800 hover:underline cursor-pointer"
          onClick={() => onClose()}
        >
          Close
          <XMarkIcon className="w-4 h-4" />
        </span>
      </div>

      {renderList()}

      {isEntity && (
        <div className="mt-4">
          <Entities user={user} isLoading={loading} onSaveEntity={onSaveEntity} />
        </div>
      )}

      {renderHistoryModal()}
      {!!rejectId && <CertFormatReasonModal forUser={false} onClose={onRejectClose} />}
    </div>
  )
}
