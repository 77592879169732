import {
  ArrowDownIcon,
  BuildingLibraryIcon,
  HomeIcon,
  PlusIcon,
  QuestionMarkCircleIcon,
} from '@heroicons/react/24/outline'
import { LayoutLoading } from 'components/LayoutLoading'
import { Loading } from 'components/Loading'
import { AccountType, IRADetails } from 'config'
import { Tooltip } from 'flowbite-react'
import { IRAType } from 'pages/Auth/SignUp/Section'
import { CompleteVerificationProgress } from 'pages/Marketplace'
import { type DbBalance, type DBInvestment, DepositModal, PaymentModalType, WithdrawModal } from 'pages/Payment'
import { InvestDetails } from 'pages/Payment/modal'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import type { RootState } from 'reducers'
import { getBalances, getInvestmentHolders, getThisMonthEstimate } from 'services/apis'
import { firstName, flagLink, isInvestableProfile, normalizeNumber, useTitle } from 'utils'

import { AccountsSection } from './Accounts'
import { ActiveInvestmentsSection } from './ActiveInvestments'
import { EarningOverview } from './EarningOverview'
import { HistorySection } from './History'
import { InvestTab } from './Invests'

interface BalanceStatus {
  title: string
  amount: number
}

const balanceTexts: Record<string, string> = {
  available: 'Cash Balance',
  deposited: 'Lifetime Deposited',
  holding: 'Pending Withdrawal',
  upcoming: 'Upcoming Deposit',
}

enum ContentTypes {
  Investments,
  Accounts,
  History,
}

export const DashboardPage = () => {
  useTitle('Dashboard')
  const navigate = useNavigate()

  const params = useParams()
  const { profile } = useSelector((state: RootState) => state.auth)
  const { depositId } = useSelector((state: RootState) => state.application)

  const isCustodian =
    [AccountType.Ira].includes(profile.accountType) &&
    (profile.accountDetails as IRADetails)?.IRA_Type === IRAType.Custodian

  const [isLoading, setLoading] = useState(false)
  const [isLoadingBalance, setLoadingBalance] = useState(true)
  const [balance, setBalance] = useState<DbBalance | null>(null)
  const [estimateProfit, setEstimateProfit] = useState(0)
  const [isVerificationProgress, showVerificationProgress] = useState('')
  const [pendingPaidOffReturns, setPendingPaidOffReturns] = useState(0)
  const [activeInvestCount, setActiveInvestCount] = useState(0)
  const [holders, setHolders] = useState<string[]>([])
  const [holder, setHolder] = useState('')

  const [contentType, setContentType] = useState<ContentTypes>(ContentTypes.Investments)
  const [selectedInvest, setSelectedInvest] = useState<DBInvestment | null>(null)
  const [paymentModalType, setPaymentModalType] = useState<PaymentModalType | null>(null)

  useEffect(() => {
    getBalances(false, holder, params.userId)
      .then((values) => setBalance(values))
      .then(() => getThisMonthEstimate(params?.userId, holder))
      .then(({ value, pendingReturns }) => {
        setEstimateProfit(value)
        setPendingPaidOffReturns(pendingReturns)
      })
      .finally(() => setLoadingBalance(false))
  }, [params, holder])

  useEffect(() => {
    getInvestmentHolders(params.userId).then((res) => {
      setHolders(res)
    })
  }, [params])

  useEffect(() => {
    if (!params.depositId) return
    if (params.depositId == `${depositId}`) onTransaction('/deposit', PaymentModalType.Deposit)
    else navigate('/dashboard')
  }, [])

  const isLargeBalance = useMemo(() => {
    if (!balance) return false
    const maxValue = Math.max(
      ...['available', 'invested', 'profits'].map((key) => Number((balance as any)[key])).filter((v) => !isNaN(v)),
    )
    return maxValue > 1000000
  }, [balance])

  const balanceSize = useMemo(() => {
    if (isLargeBalance) return 'text-xl xl:text-lg'
    return 'text-xl'
  }, [isLargeBalance])

  const balances: BalanceStatus[] = [
    {
      title: 'Total Profits',
      amount: normalizeNumber(balance?.profits || 0, 2),
    },
    {
      title: 'Invested Cash',
      amount: normalizeNumber(balance?.invested || 0),
    },
  ]

  const onTransaction = async (url: string, type: PaymentModalType | null = null) => {
    if (isCustodian) {
      toast(`A custodian-controlled SDIRA account should be managed by an admin. Please contact support.`, {
        type: 'error',
      })
      return
    }

    const isInvestable = await isInvestableProfile(type === PaymentModalType.Withdraw)
    if (!isInvestable) {
      showVerificationProgress(url)
      return
    }

    if (type == null) {
      navigate(url)
      return
    }

    setPaymentModalType(type)
  }

  const renderBalance = (
    title: string | JSX.Element,
    amount: string | number,
    isGreen: boolean = false,
    key: number = 0,
  ) => {
    return (
      <div key={key} className="bg-white rounded-lg px-4 py-4 md:py-2">
        <p className={`${isGreen ? 'text-light-green' : ''} mb-1 font-semibold text-lg`}>${amount}</p>
        <p className="text-gray-500 text-xs flex items-center gap-2 capitalize">{title}</p>
      </div>
    )
  }

  const renderContent = () => {
    switch (contentType) {
      case ContentTypes.Investments:
        return (
          <ActiveInvestmentsSection
            setActiveInvestCount={(v: number) => {
              setActiveInvestCount(v)
              setLoading(false)
            }}
            setHolder={setHolder}
            holders={holders}
            isLoading={isLoading}
            setLoading={setLoading}
            onSelect={(invest: DBInvestment) => setSelectedInvest(invest)}
            userId={params.userId}
            userName={params.userName}
          />
        )

      case ContentTypes.Accounts:
        return <AccountsSection setLoading={setLoading} showVerificationProgress={showVerificationProgress} />

      case ContentTypes.History:
        return <HistorySection setLoading={setLoading} />
    }
  }

  const totalEarned = (
    <div className="bg-gray-100 px-4 py-4 md:py-8 lg:flex lg:flex-col grid grid-cols-2 xs:grid-cols-3 md:grid-cols-4 lg:grid-cols-1 gap-4 pb-2">
      <div className="flex items-center col-span-full">
        <p className="flex-1 font-semibold">Total Earned</p>
        <p className="text-xs px-2 py-1 bg-white rounded-sm">YTD</p>
        {/* <p className="text-xs px-2 py-1">All Time</p> */}
      </div>
      {balances.map((v, index) => renderBalance(v.title, v.amount, index == 0, index))}
      <div className="w-full border-t col-span-full" />

      {balance &&
        ['available', 'withdrawn', 'upcoming', 'holding'].map((key, index) =>
          renderBalance(balanceTexts[key] || key, normalizeNumber((balance as any)[key], 2), false, index),
        )}
      {renderBalance(
        params?.userName ? `${params.userName + `'s Next Scheduled Distribution`}` : 'Your Next Scheduled Distribution',
        normalizeNumber(estimateProfit, 2),
        false,
      )}

      {!!pendingPaidOffReturns &&
        renderBalance(
          <>
            Pending Return of Funds
            <Tooltip className="normal-case" content="These funds will be available in 24 hours">
              <span className="cursor-pointer text-gray-500">
                <QuestionMarkCircleIcon className="w-[14px] h-[14px]" />
              </span>
            </Tooltip>
          </>,
          normalizeNumber(pendingPaidOffReturns),
          false,
        )}

      {balance && !params?.userId && (
        <p className="text-gray-500 text-2xs col-span-full">Your earnings history will appear on Payment page.</p>
      )}
    </div>
  )

  return (
    <div className="w-full relative bg-gray-100/50">
      <div className="fullContent relative">
        <div className="lg:grid grid-cols-4 gap-x-10">
          <div className="col-span-3 py-4">
            {params?.userName ? (
              <p className="font-title text-3xl font-bold mb-4">{params.userName}'s Dashboard</p>
            ) : (
              <p className="font-title text-3xl font-bold mb-4">Welcome back, {firstName(profile.name)}</p>
            )}
            <div className="grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-4">
              <div className="md:col-span-2 border rounded-lg overflow-hidden divide-y">
                <div
                  className="flex gap-4 items-center p-4 cursor-pointer hover:bg-gray-100 bg-white"
                  onClick={() => setContentType(ContentTypes.Investments)}
                >
                  <HomeIcon className="text-indigo-500 w-12 h-12" />
                  <div className="flex lg:block justify-between items-baseline flex-1">
                    {!activeInvestCount && isLoading ? (
                      <Loading />
                    ) : (
                      <p className="text-2xl font-semibold">{activeInvestCount}</p>
                    )}
                    <p className="text-lg lg:text-xs text-gray-500 text-left">Active Properties</p>
                  </div>
                </div>

                <div className="xs:flex sm:block md:flex items-center bg-gray-100">
                  <div
                    className="flex items-center px-4 py-6 gap-4 flex-1 hover:bg-white cursor-pointer"
                    // onClick={() => setContentType(ContentTypes.Accounts)}
                  >
                    <img src={flagLink('US')} className="w-10 h-10 mx-2 rounded-full object-cover" />
                    <div>
                      {isLoadingBalance ? (
                        <Loading />
                      ) : (
                        <p className={`font-bold ${balanceSize}`}>
                          ${normalizeNumber((balance?.available || 0) + (balance?.invested || 0), 2)}{' '}
                          <span className="text-gray-500">USD</span>
                        </p>
                      )}
                      <p className="text-sm lg:text-xs text-gray-500 text-left">Portfolio Balance</p>
                    </div>
                  </div>

                  {!params?.userName && (
                    <div className="flex-1 grid grid-cols-3 items-center px-4 py-2">
                      <div className="text-center flex flex-col justify-center items-center">
                        <div
                          className="bg-light-green p-3 text-white rounded-full mb-1 cursor-pointer hover:bg-green-600"
                          onClick={() => onTransaction('/deposit', PaymentModalType.Deposit)}
                        >
                          <PlusIcon className="w-6 h-6" />
                        </div>
                        <p className="text-2xs font-semibold text-gray-700">Deposit</p>
                      </div>

                      <div className="text-center flex flex-col justify-center items-center">
                        <div
                          className="bg-white p-3 text-gray-700 rounded-full mb-1 cursor-pointer hover:bg-indigo-500 hover:text-white"
                          onClick={() => onTransaction('/withdraw', PaymentModalType.Withdraw)}
                        >
                          <ArrowDownIcon className="w-6 h-6" />
                        </div>
                        <p className="text-2xs font-semibold text-gray-700">Withdraw</p>
                      </div>

                      {/* <div className="text-center flex flex-col justify-center items-center">
                      <div
                        className="bg-white p-3 text-gray-700 rounded-full mb-1 cursor-pointer hover:bg-indigo-500 hover:text-white"
                        onClick={() => navigate('/payment/transaction')}
                      >
                        <ClockIcon className="w-6 h-6" />
                      </div>
                      <p className="text-2xs font-semibold text-gray-700">History</p>
                    </div> */}

                      <div className="text-center flex flex-col justify-center items-center">
                        <div
                          className="bg-white p-3 text-gray-700 rounded-full mb-1 cursor-pointer hover:bg-indigo-500 hover:text-white"
                          onClick={() => navigate('/payment')}
                        >
                          <BuildingLibraryIcon className="w-6 h-6" />
                        </div>
                        <p className="text-2xs font-semibold text-gray-700">Payment Methods</p>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className="p-4 border rounded-lg flex flex-col min-h-[15rem] md:min-h-[inherit] relative">
                <EarningOverview userId={params.userId} holder={holder} />
              </div>

              <div className="col-span-full lg:hidden block">{totalEarned}</div>

              <div className="col-span-full py-4 relative">
                <LayoutLoading show={isLoading} />
                {renderContent()}
              </div>
            </div>
          </div>
          <div className="lg:block hidden">{totalEarned}</div>

          {contentType == ContentTypes.Investments && (
            <div className="col-span-full">
              <InvestTab userId={params.userId} userName={params.userName} holders={holders} />
            </div>
          )}
        </div>

        {!!isVerificationProgress && (
          <CompleteVerificationProgress
            investReady
            from={isVerificationProgress}
            onClose={() => showVerificationProgress('')}
          />
        )}

        {selectedInvest && (
          <InvestDetails
            data={selectedInvest}
            onClose={() => setSelectedInvest(null)}
            isMine={!(params.userId || params.userName)}
          />
        )}

        {paymentModalType == PaymentModalType.Deposit && <DepositModal onClose={() => setPaymentModalType(null)} />}
        {paymentModalType == PaymentModalType.Withdraw && <WithdrawModal onClose={() => setPaymentModalType(null)} />}
      </div>
    </div>
  )
}
