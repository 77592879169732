import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import { Loading } from 'components/Loading'
import { TransactionStatus, TransactionStatusTextMap } from 'components/TransactionStatus'
import { itemCountPerPage } from 'config'
import type { DbTask } from 'pages/Admin/type'
import type { User } from 'pages/User/type'
import { UserDetails } from 'pages/User/UserDetails'
import { TransferIntentCreateMode } from 'plaid'
import { useEffect, useState } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import { findTransactions, getUser } from 'services/apis'
import { Input2, Pagination, Select2 } from 'stories/components'
import { formatTime, normalizeNumber, renderHeader, useTitle } from 'utils'

import { type DbTransaction, TransactionMethod } from '../constants'
import { MannualTransaction } from './ManualTransaction'
import { TransactionDetails } from './TransactionDetails'

export const ManageTransactionsPage = () => {
  useTitle('Transaction Management')

  const [searchParams] = useSearchParams()
  const query = searchParams.get('query') || ''
  const defaultQuery = query.replace(/_/g, '#')

  const [filters, setFilters] = useState<Record<string, any>>({
    query: defaultQuery,
    direction: '',
    method: '',
    status: '',
    orderBy: 'id',
    orderDir: '-1',
    pageNum: 0,
  })
  const [filterQuery, setFilterQuery] = useState(filters.query)
  const [total, setTotal] = useState(0)
  const [isLoading, setLoading] = useState(true)
  const [transactions, setTransactions] = useState<DbTransaction[]>([])
  const [selectedTrx, setSelectedTrx] = useState<DbTransaction | null>()
  const [selectedUser, setSelectedUser] = useState<User | null>(null)
  const [isGetUsersOnce, setIsGetUsersOnce] = useState(false)

  useEffect(() => {
    window.scrollTo(window.scrollX, 0)
    filterData(filters).then(() => {
      setIsGetUsersOnce(true)
    })
  }, [])

  useEffect(() => {
    if (!isGetUsersOnce) return
    const timeOutId = setTimeout(() => !isLoading && onChangeFilter('pageNum', 0), 700)
    return () => clearTimeout(timeOutId)
  }, [filterQuery])

  const filterData = (filters: Record<string, any>, _pageNum: number = -1) => {
    if (filters?.query) filters.query = filters.query.trim()
    if (_pageNum === -1) _pageNum = filters.pageNum
    setLoading(true)
    if (!filters.method) delete filters.method
    if (!filters.direction) delete filters.direction
    if (!filters.status) delete filters.status
    else filters.status = String(filters.status).toLowerCase()

    return findTransactions({
      ...filters,
      skip: _pageNum * itemCountPerPage,
      count: itemCountPerPage,
    })
      .then(({ data, total }) => {
        setTotal(total)
        setTransactions(data)
      })
      .finally(() => setLoading(false))
  }

  const onOpenUser = (userId: number) => {
    getUser(userId).then((data) => {
      data && setSelectedUser(data)
    })
  }

  const onPageNavigate = (num: number) => {
    onChangeFilter('pageNum', num)
  }

  const onChangeFilter = (
    key: 'query' | 'method' | 'direction' | 'status' | 'orderBy' | 'orderDir' | 'pageNum',
    value: any,
    refetch = true,
  ) => {
    if (isLoading) return
    const newFilters = Object.assign({}, filters)
    newFilters[key] = value
    setFilters(newFilters)
    if (key === 'query') setFilterQuery(value)
    else if (refetch) filterData(newFilters)
  }

  const onCloseModal = (isUpdated: boolean) => {
    setSelectedTrx(null)
    isUpdated && filterData(filters)
  }

  const _renderHeader = (title: string, sortable: boolean = false, key: string) => {
    return renderHeader({
      title,
      index: 0,
      key,
      sortable,
      onSort: (key: string, sortOrder: number) => {
        const newFilters = Object.assign({}, filters)
        newFilters['orderBy'] = key
        newFilters['orderDir'] = sortOrder
        setFilters(newFilters)
        filterData(newFilters)
      },
      sortOrder: filters.orderBy == key ? filters.orderDir : 0,
    })
  }

  const renderMethod = (item: DbTransaction) => {
    if (item.method != TransactionMethod.Manual) return item.method
    return item.direction != TransferIntentCreateMode.Payment ? 'Debit' : 'Credit'
  }

  return (
    <div className="fullContent pt-16 min-h-[100vh]">
      <p className="text-4xl font-semibold mb-10 flex gap-4 items-center">
        Transaction Management ({total}) {isLoading && <Loading />}
      </p>

      <div className="grid items-center grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mt-4">
        <Input2
          type="search"
          title="Search"
          hasIcon
          icon={<MagnifyingGlassIcon className="w-4 h-4" />}
          value={filters.query}
          onChange={(value) => onChangeFilter('query', value)}
        />
        <Select2
          id="Method"
          title="Method"
          options={{ wire: 'Wire', plaid: 'Plaid' }}
          value={filters.method}
          hasDefaultOption
          defaultOptionText="All"
          onChange={(value) => onChangeFilter('method', value)}
        />
        <Select2
          id="Direction"
          title="Direction"
          options={{ PAYMENT: 'Deposit', DISBURSEMENT: 'Withdraw' }}
          value={filters.direction}
          hasDefaultOption
          defaultOptionText="All"
          onChange={(value) => onChangeFilter('direction', value)}
        />
        <Select2
          id="status"
          title="Status"
          options={TransactionStatusTextMap}
          value={filters.status}
          hasDefaultOption
          defaultOptionText="All"
          onChange={(value) => onChangeFilter('status', value)}
        />
      </div>
      <div className="table-container mt-4 relative overflow-x-auto shadow-md sm:rounded-lg">
        <table className="w-full text-sm text-left text-gray-900 dark:text-gray-400 pl-6">
          <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              {_renderHeader('No', false, 'no')}
              {_renderHeader('TrxID', true, 'id')}
              {_renderHeader('Task', false, 'task')}
              {_renderHeader('Name', true, 'name')}
              {_renderHeader('Email', true, 'email')}
              {_renderHeader('Method', true, 'method')}
              {_renderHeader('Amount', true, 'amount')}
              {_renderHeader('Status', true, 'status')}
              {_renderHeader('Created At', true, 'createdAt')}
              {_renderHeader('Updated At', true, 'updatedAt')}
            </tr>
          </thead>
          <tbody className="text-[14px]">
            {transactions.map((item, index: number) => {
              const task = (item as any).task as DbTask
              return (
                <tr key={index} className={`border-b ${index % 2 && 'bg-slate-50'} text-gray-900`}>
                  <td className="pl-3 py-3">{filters.pageNum * itemCountPerPage + index + 1}</td>
                  <td
                    className="px-2 py-2 font-bold text-indigo-500 cursor-pointer hover:underline"
                    onClick={() => setSelectedTrx(item)}
                  >
                    #{item.id}
                  </td>
                  <td className="px-2 py-2 font-bold text-indigo-500 cursor-pointer hover:underline">
                    {task && (
                      <Link
                        to={`/tasks?query=_${task.id}&taskType=${
                          task.status == 'active' ? 'allActive' : 'allCompleted'
                        }`}
                      >
                        #{task.id}
                      </Link>
                    )}
                  </td>
                  <td className="px-2 py-2">
                    <div
                      className="font-bold text-indigo-500 cursor-pointer whitespace-nowrap hover:underline"
                      onClick={() => item.userId && onOpenUser(item.userId)}
                    >
                      {item.name}
                    </div>
                  </td>
                  <td className="px-2 py-2">{item.email}</td>
                  <td className="px-2 py-2 capitalize">{renderMethod(item)}</td>
                  <td className="px-2 py-2 whitespace-nowrap">
                    {item.direction == TransferIntentCreateMode.Payment ? '+' : '-'} ${normalizeNumber(item.amount, 2)}
                  </td>
                  <td className="px-2 py-2 capitalize">
                    <TransactionStatus status={item.status} onClick={() => setSelectedTrx(item)} />
                  </td>
                  <td className="px-2 py-2 whitespace-nowrap">{formatTime(item.createdAt)}</td>
                  <td className="px-2 py-2 whitespace-nowrap">{formatTime(item.updatedAt)}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>

      <div className="flex justify-end items-center mt-3 mb-3">
        <Pagination
          totalCount={total}
          itemCountPerPage={itemCountPerPage}
          onNavigate={onPageNavigate}
          pageNum={filters.pageNum}
        />
      </div>

      {selectedTrx &&
        (selectedTrx.method == TransactionMethod.Manual ? (
          <MannualTransaction
            id={selectedTrx.id}
            user={
              {
                name: selectedTrx.name,
                email: selectedTrx.email,
                userId: selectedTrx.userId,
              } as any as User
            }
            onClose={onCloseModal}
          />
        ) : (
          <TransactionDetails id={selectedTrx.id} onClose={onCloseModal} />
        ))}

      {selectedUser && <UserDetails readOnly data={selectedUser} onClose={() => setSelectedUser(null)} />}
    </div>
  )
}
