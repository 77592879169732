import type { Loan } from 'pages/Marketplace'
import type { DBInvestment } from 'pages/Payment'
import type { User } from 'pages/User/type'

export interface DBProfit {
  id: number
  loan: Loan
  user: User
  investment: DBInvestment
  amount: number
  from: Date
  to: Date
  status: ProfitStatus
  override: boolean
  histories: ProfitUpdateHistory[]
  createdAt: Date
  updatedAt: Date
}

export enum ProfitStatus {
  Pending = 'pending',
  Approved = 'approved',
  Rejected = 'rejected',
}

export const ProfitStatusText = {
  pending: 'Pending',
  approved: 'Approved',
  rejected: 'Rejected',
}

export const monthText = {
  1: '1',
  2: '2',
  3: '3',
  4: '4',
  5: '5',
  6: '6',
  7: '7',
  8: '8',
  9: '9',
  10: '10',
  11: '11',
  12: '12',
}

export const yearText = {
  2023: '2023',
  2024: '2024',
  2025: '2025',
  2026: '2026',
  2027: '2027',
  2028: '2028',
  2029: '2029',
  2030: '2030',
}

export const ProfitStatusColorMap = {
  [ProfitStatus.Pending]: 'bg-indigo-200 text-gray-800',
  [ProfitStatus.Approved]: 'bg-green-600 text-white',
  [ProfitStatus.Rejected]: 'bg-red-500 text-white',
}

export const ProfitStatusHoverColorMap = {
  [ProfitStatus.Pending]: 'text-indigo-200',
  [ProfitStatus.Approved]: 'text-green-600',
  [ProfitStatus.Rejected]: 'text-red-500',
}

export const ProfitStatusHoverBorderColorMap = {
  [ProfitStatus.Pending]: 'border-indigo-200',
  [ProfitStatus.Approved]: 'border-green-600',
  [ProfitStatus.Rejected]: 'border-red-500',
}

export interface ProfitUpdateHistory {
  updatedBy: string
  status: ProfitStatus
  comment?: string
  createdAt: number
  override?: boolean
}

export interface ApproveFailedResult {
  id: number
  name: string
  message: string
  success: boolean
}
