import { LinkIcon } from '@heroicons/react/24/outline'
import { Image } from 'components/Image'
import { LayoutLoading } from 'components/LayoutLoading'
import { Loading } from 'components/Loading'
import { TransactionColorMap, TransactionStatus } from 'components/TransactionStatus'
import { AccountType, IRADetails, PenaltyPercent } from 'config'
import { Tooltip } from 'flowbite-react'
import moment from 'moment-timezone'
import { IRAType } from 'pages/Auth/SignUp/Section'
import { estimatedRemainingProfit } from 'pages/Dashboard/logic'
import { EnvelopeColorMap, ProfitStatus } from 'pages/Invest'
import { remainingLoanTerm } from 'pages/Marketplace/InvestCard'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { RootState } from 'reducers'
import { getEnvelopeUrl, getInvestment, signSellBackInvestment } from 'services/apis'
import { Button, Modal, PlainTable } from 'stories/components'
import {
  confirm,
  dayProfit,
  formatDate,
  formatDateLong,
  formatTimeLong,
  monthlyProfit,
  normalizeNumber,
  thisTime,
  toS3Link,
} from 'utils'

import { type DBInvestment, DbProfit, type InvestEvent, InvestStatus, ProfitColorMap } from '../constants'

export const InvestDetails = ({
  data: _data,
  isMine = true,
  isAdmin = false,
  onClose,
}: {
  data: DBInvestment
  isMine?: boolean
  isAdmin?: boolean
  onClose: Function
}) => {
  const { profile } = useSelector((state: RootState) => state.auth)
  const isCustodian =
    [AccountType.Ira].includes(profile.accountType) &&
    (profile.accountDetails as IRADetails)?.IRA_Type === IRAType.Custodian

  const [data, setData] = useState<DBInvestment>(_data)
  const [action, setAction] = useState('')

  useEffect(() => {
    setAction('loading')
    getInvestment(data.id)
      .then((v) => setData(v))
      .finally(() => setAction(''))
  }, [])

  const onLinkRetradedDoc = () => {
    if (action) return
    setAction('retradedLink')
    getEnvelopeUrl(data.loan.id, data.retradeEnvelopeId!)
      .then((res) => {
        if (res?.url) window.open(res.url, '_blank')
      })
      .finally(() => setAction(''))
  }

  const renderData = useMemo(() => {
    const { loan } = data
    const strAmount = normalizeNumber(data.amount)

    const result: Record<string, any> = {
      'Loan Amount': `$ ${normalizeNumber(loan.loanAmount)}`,
      APY: `${normalizeNumber(loan.ytm, 2)} %`,
      'Estimated Remaining Interest': `$ ${normalizeNumber(estimatedRemainingProfit(data), 2)}`,
      'Term Remaining': remainingLoanTerm(data.loan.maturityDate),
      'Investment Amount': `$ ${strAmount}`,
      // Method: data.method,
      Status: <TransactionStatus status={data.status} />,
      'Sign Request Date': formatDateLong(data.createdAt),
      'Document Status': (
        <div className="flex gap-2 items-center justify-end px-4 lg:px-0 lg:justify-start capitalize">
          <span className={`w-2 h-2 rounded-full ${EnvelopeColorMap[data.envelopeStatus]}`} />
          {data.envelopeStatus}
        </div>
      ),
    }
    if (data.status != InvestStatus.Settled) delete result['Estimated Remaining Interest']
    if (data.startDate) result['Invested Date'] = formatDateLong(data.startDate)
    if (data.lastProfitDate) result['Last Distribution Date'] = formatDateLong(data.lastProfitDate)
    result['Monthly Profit'] = `$ ${normalizeNumber(monthlyProfit(data.amount, data.ytm), 2)}`
    if (data.profit) result['Distributions'] = <p className="text-base font-bold text-green-500">+ $ {data.profit}</p>
    if (data.penalty && Number(data.penalty))
      result['Transaction Fee'] = <p className="text-base font-bold text-red-500">- ${normalizeNumber(data.penalty)}</p>

    return result
  }, [data, action])

  const profits = useMemo(() => {
    if (!data.profits) return null

    const len = data.profits.length
    let lastProfit: DbProfit = {
      id: 0,
      from: len
        ? formatDate(
            moment(data.profits[len - 1].to)
              .add(1, 'day')
              .toDate(),
          )
        : formatDate(moment(data.startDate).add(1, 'day').toDate()),
      to: formatDate(thisTime().toDate()),
      amount: 0,
      status: ProfitStatus.Pending,
    } as any
    if (data.status != InvestStatus.Settled) lastProfit = null as any
    else {
      lastProfit.amount = dayProfit(data.amount, data.ytm, moment().diff(lastProfit.from, 'days'))
    }

    return [...data.profits, lastProfit]
      .filter((v) => v != null)
      .map((v, index) => [
        <div className="flex gap-1" key={index}>
          <span>{index + 1}</span>
          {!v.id ? (
            <></>
          ) : isAdmin ? (
            <Tooltip content="Go to Distribution">
              <Link
                to={`/manageProfits?query=_${v.id}&status=${v.status}`}
                className="font-bold cursor-pointer text-indigo-500 hover:underline"
              >
                #{v.id}
              </Link>
            </Tooltip>
          ) : (
            <span className="font-bold cursor-pointer text-indigo-500 hover:underline">#{v.id}</span>
          )}
        </div>,
        formatDateLong(v.from),
        formatDateLong(v.to),
        `$ ${normalizeNumber(v.amount, 2)}`,
        <p className={`capitalize ${ProfitColorMap[v.status]} inline-block px-2 py-1 rounded text-sm capitalize`}>
          {v.status}
        </p>,
      ])
  }, [data])

  const onLink = () => {
    if (isCustodian && !data.envelopeId) {
      toast(`A custodian-controlled SDIRA account should be managed by an admin. Please contact support.`, {
        type: 'error',
      })
      return
    }

    if (action) return

    setAction('link')
    getEnvelopeUrl(data.loan.id, data.envelopeId)
      .then((res) => {
        if (res?.url) window.open(res.url, '_blank')
      })
      .finally(() => setAction(''))
  }

  const onSellBack = async () => {
    if (action) return

    const result = await confirm(
      <p className="mb-4">
        There is a{' '}
        <b>
          {100 * PenaltyPercent}% ($ {normalizeNumber(data.amount * PenaltyPercent)})
        </b>{' '}
        transaction fee to exit the investment prior to payoff. Please confirm you wish to proceed.
      </p>,
    )
    if (!result) return

    setAction('sellback')
    signSellBackInvestment(data.id)
      .then(({ redirectUrl }) => {
        window.open(redirectUrl, '_self')
      })
      .catch(() => setAction(''))
  }

  const renderEvent = (event: InvestEvent) => (
    <div className="flex gap-2 mb-2" key={event.event_id}>
      <div className="flex flex-col">
        <div className="p-0.5 border rounded-full">
          <div className={`w-3 h-3 ${TransactionColorMap[event.event_type]} rounded-full`} />
        </div>
        <div className="border-l ml-2 flex-1" />
      </div>
      <div className="text-desc w-full">
        <div className="flex items-center gap-2 mb-2 justify-between">
          <p className="text-sm text-gray-800 capitalize">
            <TransactionStatus status={event.event_type} />
          </p>
        </div>
        <p className="mb-2">{event.description}</p>
        <p>{formatTimeLong(event.timestamp)}</p>
      </div>
    </div>
  )

  const { loan } = data

  return (
    <Modal isOpen title={`Investment #${data.id}`} titleOkay="" titleCancel="Close" onClose={() => onClose(false)}>
      <div className="lg:w-196 w-[90vw] -m-6 overflow-y-auto">
        <LayoutLoading show={action == 'sellback'} />

        <div className="md:flex">
          <div className="flex-[2]">
            <Image src={toS3Link(loan.images)} className="w-full aspect-video object-cover" />
            <div className={`text-gray-600 text-md p-6`}>
              <p className="text-base text-gray-700 font-bold truncate mb-2 uppercase">
                {loan.propertyAddress} #{loan.id}
              </p>
              {Object.keys(renderData).map((title) => (
                <div className="py-1.5 flex gap-2 justify-between items-center border-b" key={title}>
                  <p className="text-desc">{title}</p>
                  <div className="text-base capitalize">{renderData[title]}</div>
                </div>
              ))}

              {data.status == InvestStatus.Pending && (
                <p className="text-desc mb-2">Open document and sign to complete investment.</p>
              )}
              {data.status == InvestStatus.Settled && isMine && (
                <div className="mt-4">
                  <Button outline color="yellow" onClick={onSellBack} size="sm">
                    <p className="flex gap-2 items-center">Retrade to Finresi.</p>
                  </Button>
                  <p className="text-desc">You need to pay {100 * PenaltyPercent}% transaction fee if you retrade.</p>
                </div>
              )}
            </div>
          </div>
          <div className="flex-1 bg-slate-100 p-6">
            <p className="mb-2">Documents</p>

            <div className="border-b mb-6 text-sm">
              <a className="text-link flex items-center gap-2 mb-2 cursor-pointer" onClick={onLink}>
                {data.status === InvestStatus.Pending ? 'Investment Sign' : 'Open Signed Document'}
                {action == 'link' ? <Loading /> : <LinkIcon className="w-4 h-4" />}
              </a>
              {!!data.penalty && !!Number(data.penalty) && (
                <a className="text-link flex items-center gap-2 mb-2 cursor-pointer" onClick={onLinkRetradedDoc}>
                  Retrade to Finresi Sign
                  {action == 'retradedLink' ? <Loading /> : <LinkIcon className="w-4 h-4" />}
                </a>
              )}
            </div>

            <p className="mb-2">Events</p>
            <div className="w-full">{data.events.map(renderEvent)}</div>
          </div>
        </div>
        <div className="w-full px-6 py-2 text-sm">
          <p className="text-gray-700 font-bold mb-2">Distributions</p>
          {profits ? (
            <PlainTable
              tdClass="text-sm px-6 py-2 whitespace-nowrap"
              header={['No', 'From', 'To', 'Amount', 'Status']}
              data={profits}
            />
          ) : (
            <Loading />
          )}
        </div>
      </div>
    </Modal>
  )
}
