import cloneDeep from 'clone-deep'
import type { InputType } from 'config'
import moment from 'moment-timezone'
import { InvestReadyCert, InvestReadyStatus } from 'pages/Profile/InvestReady/type'
import { useEffect, useState } from 'react'
import { Modal } from 'stories/components'
import { formatDateYMD, InputConvert, InputValidate, RenderInput } from 'utils'

const defaultInputs = (): Record<string, InputType> => {
  return {
    expiresOn: {
      inputType: 'text',
      type: 'date',
      title: 'Expires On',
      value: '',
      span: 2,
      required: true,
    },
  }
}

export const InvestReadyApproveModal = ({ data, onClose }: { data: InvestReadyCert; onClose: Function }) => {
  const [loading, setLoading] = useState(false)
  const [inputs, setInputs] = useState(defaultInputs())

  useEffect(() => {
    let newInputs = cloneDeep(inputs)
    if (data.status == InvestReadyStatus.Pending)
      newInputs.expiresOn.value = formatDateYMD(moment().add(3, 'months').toDate())
    else newInputs.expiresOn.value = formatDateYMD(data.expiresOn)
    setInputs(newInputs)
  }, [])

  const onChange = (key: string, value: string) => {
    let newInputs = cloneDeep(inputs)
    value = InputConvert(newInputs[key], value)
    newInputs[key].error = InputValidate({ ...newInputs[key], value })
    newInputs[key].value = value
    setInputs(newInputs)
  }

  const onSubmit = async () => {
    let hasError = false

    let newInputs = cloneDeep(inputs)
    const data: Record<string, any> = {}
    for (const key in inputs) {
      newInputs[key].error = InputValidate(newInputs[key])
      data[key] = newInputs[key].value
      if (newInputs[key].error) hasError = true
    }
    setInputs(newInputs)
    if (hasError) return

    setLoading(true)
    onClose(data.expiresOn || '')
  }

  return (
    <Modal
      title="Are you sure to approve this user as Accredited Investor?"
      titleOkay="Submit"
      loading={loading}
      disabled={!inputs.expiresOn.value}
      onClose={() => onClose(null)}
      onOk={onSubmit}
      isOpen
    >
      <div className="text-gray-900">
        {data.status == InvestReadyStatus.Pending && (
          <p className="text-sm mb-2 border rounded p-2">
            Please ensure that all investor values are entered correctly before approving the user.
          </p>
        )}

        <div className="md:w-144 grid gap-4 md:grid-cols-2 grid-cols-1 mb-3 mt-2 text-sm">
          {Object.keys(inputs).map((key, index) => {
            const input = inputs[key]
            return (
              <div className={`input md:col-span-${input.span || 1}`} key={index}>
                <RenderInput input={input} Key={key} onChange={onChange} />
              </div>
            )
          })}
        </div>
      </div>
    </Modal>
  )
}
