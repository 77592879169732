import type { DBProfit } from 'pages/Invest'
import type { Loan } from 'pages/Marketplace'
import type { User } from 'pages/User/type'
import {
  DepositSwitchTargetAccountAccountSubtypeEnum,
  Institution,
  TransferEvent,
  TransferEventType,
  TransferIntentCreateMode,
  TransferStatus,
} from 'plaid'
import type { PlaidAccount } from 'react-plaid-link'
import { addDays, dateDiffDays, dayProfit, monthlyProfit, thisTime } from 'utils'

export enum TransactionMethod {
  Plaid = 'plaid',
  Wire = 'wire',
  Manual = 'manual',
}

export interface WireTransactionData {
  bankName: string
  institutionId: string
  routingNumber: string
  accountNumber: string
  subType: DepositSwitchTargetAccountAccountSubtypeEnum
}

export interface PlaidTransactionData {
  linkSessionId: string
  accountId: string
}

export interface ManualTransactionData {
  note: string
}

export interface DbPlaidAccount {
  institution: Institution
  account: PlaidAccount
  accountId: string
  deleted: boolean
}

export const ViewableTransferEvents: TransferEventType[] = [
  TransferEventType.Pending,
  TransferEventType.Cancelled,
  TransferEventType.Failed,
  TransferEventType.Posted,
  TransferEventType.Settled,
  TransferEventType.Returned,
  TransferEventType.Swept,
  TransferEventType.SweptSettled,
  TransferEventType.ReturnSwept,
]

export interface ITransactionEvent extends TransferEvent {
  actor: string
  data?: Record<string, any>
}

export interface DbTransaction {
  id: number
  direction: TransferIntentCreateMode
  method: TransactionMethod
  amount: number
  transferId: string // Internal Transfer Id
  status: TransferStatus
  data: WireTransactionData | PlaidTransactionData | ManualTransactionData | null
  events: ITransactionEvent[]
  comment: string
  createdAt: Date
  updatedAt: Date

  // User
  name?: string
  email?: string
  userId?: number

  // Bank
  bank: {
    accountName: string
    logo?: string | null | undefined
    bankName?: string | null
    accountNumber?: number | string | null
  }
}

export interface DbBalance {
  deposited: number
  withdrawn: number
  available: number
  upcoming: number
  holding: number
  invested: number
  profits: number
}

export interface InvestEvent {
  actor?: string
  event_id: number
  timestamp: string
  event_type: InvestStatus | 'distribution'
  value?: number | string
  description: string
}

export enum InvestStatus {
  Pending = 'pending',
  Posted = 'posted',
  Settled = 'settled',
  Cancelled = 'cancelled',
  Failed = 'failed',
  Returned = 'returned',
  Retraded = 'retraded',
}

export const ActiveInvestStatus = [InvestStatus.Posted, InvestStatus.Settled]

export const PreviousInvestStatus = [
  InvestStatus.Cancelled,
  InvestStatus.Failed,
  InvestStatus.Returned,
  InvestStatus.Retraded,
]

export interface DBInvestment {
  id: number
  loan: Loan
  user: User
  status: InvestStatus
  amount: number
  ytm: number
  method: 'Balance' | 'Wire'
  createdAt: Date
  envelopeId: string
  envelopeStatus: string
  events: InvestEvent[]
  startDate: Date
  endDate: Date | null
  isPaidOff: boolean
  lastProfitDate: Date | null
  profit?: number
  pendingProfit?: number
  penalty: number
  retradeEnvelopeId?: string
  profits?: DBProfit[]
  dateFundTransfer: Date | null
  investAs: string
  investSignerName: string
  investSignerEmail: string
}

export enum ProfitStatus {
  Pending = 'pending',
  Approved = 'approved',
  Rejected = 'rejected',
}

export interface DbProfit {
  id: number
  loan: Loan
  investment: DBInvestment
  amount: number
  createdAt: Date
  from: Date
  to: Date
  status: ProfitStatus
}

export const ProfitColorMap: Record<ProfitStatus, string> = {
  [ProfitStatus.Pending]: 'bg-indigo-200 text-gray-800',
  [ProfitStatus.Approved]: 'bg-green-500 text-white',
  [ProfitStatus.Rejected]: 'bg-red-500 text-white',
}

/**
 * Histories
 */
export enum PayHistoryType {
  All = 'All',
  Investments = 'Investments',
  Withdrawals = 'Withdrawals',
  Deposits = 'Deposits',
  Distributions = 'Distributions',
  ReturnedInvestments = 'ReturnedInvestments',
}

export const PayTrxTypes = [PayHistoryType.Deposits, PayHistoryType.Withdrawals]

export interface TrxHistory extends DbTransaction {
  dataType: PayHistoryType.Withdrawals | PayHistoryType.Deposits
}

export interface InvHistory extends DBInvestment {
  dataType: PayHistoryType.Investments | PayHistoryType.ReturnedInvestments
}

export interface ProfHistory extends DbProfit {
  dataType: PayHistoryType.Distributions
}

export type PayHistory = TrxHistory | InvHistory | ProfHistory

export const monthsNameAbbr: Record<string, string> = {
  January: 'Jan',
  February: 'Feb',
  March: 'Mar',
  April: 'Apr',
  May: 'May',
  June: 'June',
  July: 'July',
  August: 'Aug',
  September: 'Sept',
  October: 'Oct',
  November: 'Nov',
  December: 'Dec',
}

export const thisMonthProfit = (v: DBInvestment) => {
  const newLastProfitDate = thisTime().add(1, 'months').date(1).subtract(1, 'days').toDate()

  let days = 0

  const remainingDays = dateDiffDays(v.loan.maturityDate, newLastProfitDate)

  if (v.lastProfitDate) {
    if (remainingDays > 0) return monthlyProfit(v.amount, v.ytm)
    else days = dateDiffDays(v.loan.maturityDate, addDays(v.lastProfitDate, 1))
  } else {
    if (remainingDays > 0) days = dateDiffDays(newLastProfitDate, addDays(v.startDate, 1))
    else days = dateDiffDays(v.loan.maturityDate, addDays(v.startDate, 1))
  }

  return dayProfit(v.amount, v.ytm, days)
}

export enum PaymentModalType {
  Deposit,
  Withdraw,
}
