import {
  API_INVEST,
  API_INVEST_BY_ID,
  API_INVEST_CUSTODIAN,
  API_INVEST_CUSTODIAN_DOC,
  API_INVEST_DECLINE,
  API_INVEST_ENVELOPE,
  API_INVEST_ENVELOPE_URL,
  API_INVEST_HOLDERS,
  API_INVEST_SELL_BACK,
  API_INVEST_SIGN,
  API_INVEST_UNSIGNED_AGREEMENT,
  API_INVEST_UPDATE_TRANSFER_DATE,
  API_INVESTORS_DETAIL_BY_LOANID,
  API_RETURN_FUNDS_FOR_PAID_OFF,
} from 'config'
import type { DBInvestment } from 'pages/Payment/constants'
import Api from 'services/api'

import { downloadFile, openS3Document } from './documents'

export const signInvestment = (loanId: number, data: Record<string, any>) => {
  return Api.post(
    API_INVEST_SIGN,
    data,
    { loanId },
    {
      timeout: 250000,
    },
  )
}

export const createCustodianInvestment = async (userId: number, loanId: number, data: Record<string, any>) => {
  const res: Blob = await Api.post(
    API_INVEST_CUSTODIAN,
    data,
    { userId, loanId },
    {
      timeout: 250000,
      responseType: 'blob',
    },
  )
  downloadFile(`Investment for Loan#${loanId}.pdf`, res)
}

export const completeCustodianInvestment = (userId: number, loanId: number, investId: number, fileList: File[]) => {
  return Api.uploadFiles(API_INVEST_CUSTODIAN_DOC, {}, { userId, loanId, investId }, fileList)
}

export const getInvestment = (id: number) => {
  return Api.get(API_INVEST_BY_ID, {}, { id })
}

export const getInvestorsDetail = (loanId: number) => {
  return Api.get(API_INVESTORS_DETAIL_BY_LOANID, {}, { loanId })
}

export const getInvestments = (
  filters: Record<string, any>,
  userId: string | undefined = undefined,
): Promise<{ total: number; data: DBInvestment[] }> => {
  if (userId) return Api.get(API_INVEST, filters, { userId })
  return Api.get(API_INVEST, filters)
}

export const getInvestmentHolders = (userId: string | undefined = undefined) => {
  if (userId) return Api.get(API_INVEST_HOLDERS, {}, { userId })
  return Api.get(API_INVEST_HOLDERS, {})
}

export const updateEnvelopeStatus = (loanId: number, envelopeId: string) => {
  return Api.post(API_INVEST_ENVELOPE, { envelopeId }, { loanId })
}

export const getEnvelopeUrl = (loanId: number, envelopeId: string) => {
  if (envelopeId.startsWith('yieldeasy/custodianInvestDocument')) {
    return openS3Document(envelopeId)
  } else return Api.get(API_INVEST_ENVELOPE_URL, {}, { loanId, envelopeId })
}

export const cancelInvestment = (id: number, distributeProfit: boolean) => {
  return Api.delete(API_INVEST_BY_ID, { distributeProfit }, { id })
}

export const returnFundsForPaidOff = (id: number, distributeProfit: boolean) => {
  return Api.delete(API_RETURN_FUNDS_FOR_PAID_OFF, { distributeProfit }, { id })
}

export const declineInvestment = (id: number) => {
  return Api.delete(API_INVEST_DECLINE, {}, { id })
}

export const openUnsignedAgreement = async (loanId: number, amount: number) => {
  const res: Blob = await Api.get(
    API_INVEST_UNSIGNED_AGREEMENT,
    { loanId, amount },
    {},
    {
      responseType: 'blob',
    },
  )
  downloadFile(`Promissory Note Secured by CSA for Loan#${loanId}.pdf`, res)
}

export const signSellBackInvestment = (id: number) => {
  return Api.post(API_INVEST_SELL_BACK, { id, envelopeId: '' })
}

export const confirmSellbackInvestment = (id: number, envelopeId: string) => {
  return Api.post(API_INVEST_SELL_BACK, { id, envelopeId })
}

export const updateDateTransfer = (id: number) => {
  return Api.get(API_INVEST_UPDATE_TRANSFER_DATE, {}, { id })
}
